import { extendTheme } from "@chakra-ui/react";
import { theme as proTheme } from "./pro/dist/index.esm.js";

import * as foundations from "./foundations/index.js";
import * as components from "./components/index.js";

// Brand

// const purple = {
//   50: "#f2e9ff",
//   100: "#d3c3f2",
//   200: "#b49ce4",
//   300: "#9675d7",
//   400: "#784ecb",
//   500: "#5f34b1",
//   600: "#4a298b",
//   700: "#351c65",
//   800: "#20103e",
//   900: "#0d041b",
// };

// darker
// https://smart-swatch.netlify.app/#ed7d14
// const orange = {
//   50: "#fff1dc",
//   100: "#fdd7b3",
//   200: "#f7bd86",
//   300: "#f3a358",
//   400: "#ef892a",
//   500: "#d57010",
//   600: "#a7570b",
//   700: "#773d06",
//   800: "#492400",
//   900: "#1e0900",
// };

// ripped from stripe

const gray = {
  0: "#ffffff",
  50: "#f6f8fa",
  100: "#ebeef1",
  150: "#d5dbe1",
  200: "#c0c8d2",
  300: "#a3acba",
  400: "#87909f",
  500: "#687385",
  600: "#545969",
  700: "#414552",
  800: "#30313d",
  900: "#1a1b25",
  950: "#10111a",
};

const purple = {
  50: "#f9f7ff",
  100: "#f2ebff",
  150: "#dfd3fc",
  200: "#d1befe",
  300: "#b49cfc",
  400: "#8d7ffa",
  500: "#625afa",
  600: "#513dd9",
  700: "#3f32a1",
  800: "#302476",
  900: "#14134e",
};

const orange = {
  50: "#fef9da",
  100: "#fcedb9",
  200: "#fcd579", // Stripe's 150
  300: "#fcbd3a",
  400: "#ff8f0e",
  500: "#ed6704",
  600: "#c84801",
  700: "#a82c00",
  800: "#842106",
  900: "#5f1a05",
  // 900: "#331302",
};

const red = {
  50: "#fff5fa",
  100: "#ffe7f2",
  150: "#ffccdf",
  200: "#ffb1cd",
  300: "#fe87a1",
  400: "#fc526a",
  500: "#df1b41",
  600: "#b3093c",
  700: "#890d37",
  800: "#68052b",
  900: "#3e021a",
};

const green = {
  50: "#ecfed7",
  100: "#d7f7c2",
  150: "#a6eb84",
  200: "#76df47",
  300: "#48c404",
  400: "#3fa40d",
  500: "#228403",
  600: "#006908",
  700: "#0b5019",
  800: "#043b15",
  900: "#02220d",
};

const blue = {
  50: "#ddfffe",
  100: "#cff5f6",
  150: "#a2e5ef",
  200: "#75d5e8",
  300: "#06b9ef",
  400: "#0096eb",
  500: "#0570de",
  600: "#0055bc",
  700: "#04438c",
  800: "#003262",
  900: "#011c3a",
};

// need: orange (warning), green (success), red (error), blue (info), brand (primary), gray (bgs)

export default extendTheme(
  {
    ...foundations,
    colors: {
      gray: gray,
      orange: orange, // warning
      red: red, // error
      green: green, // success
      blue: blue, // info
      purple: purple,
      brand: purple, //brand
    },
    components: {
      ...components,
    },
  },
  proTheme,
);
